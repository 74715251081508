/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconAIProfileSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconAIProfileSearch"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.217 15C13.729 15 16 18.416 16 21h-2c0-.46-.344-1.515-.88-2.321C12.404 17.604 11.451 17 10.216 17H7.783c-1.235 0-2.188.604-2.902 1.679C4.344 19.485 4 20.539 4 21H2c0-2.584 2.27-6 5.783-6zM9 3a5 5 0 1 1 0 10A5 5 0 0 1 9 3m0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6M16.017 10.425l.337 1.073c.103.433.44.778.881.882l1.069.333-1.073.337a1.19 1.19 0 0 0-.882.881L16.017 15l-.337-1.073a1.19 1.19 0 0 0-.882-.882l-1.069-.332 1.073-.337a1.19 1.19 0 0 0 .882-.882zM18.693 3l.541 1.723A1.91 1.91 0 0 0 20.65 6.14l1.717.534-1.724.541a1.91 1.91 0 0 0-1.416 1.416l-.534 1.717-.54-1.723a1.91 1.91 0 0 0-1.417-1.416l-1.716-.535 1.723-.54a1.91 1.91 0 0 0 1.416-1.416z"
    />
  </svg>
);
export default IconAIProfileSearch;
