import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceM, spaceL, scale210 } from '@xing-com/tokens';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${scale210};
  row-gap: ${spaceM};
  margin-top: ${scale210};

  @media ${mediaConfined} {
    flex-direction: row;
    gap: ${spaceL};
  }
`;
