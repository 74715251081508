import type { Maybe } from '@xing-com/crate-common-graphql-types';
import type {
  JobTeaserVisibleJobFragment,
  JobMatchingHighlightsV2Fragment,
} from '@xing-com/crate-jobs-graphql';

import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';
import { JobTeaserCardSkeleton } from './job-teaser-card-skeleton';

export type JobTeaserCardProps = {
  job: JobTeaserVisibleJobFragment;
  isNew?: boolean;
  url: string;
  routerState?: unknown;
  target?: '_blank' | '_self';
  matchingHighlights?: Maybe<JobMatchingHighlightsV2Fragment>;
  actions?: React.ReactElement;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onContextMenu?: () => void;
  onAuxClick?: () => void;
  onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  'data-testid'?: string;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  showRatingMarker?: boolean;
  showRefreshedDate?: boolean;
  className?: string;
};

export type JobTeaserCardXinglet = XingletInterface<JobTeaserCardProps>;

export const JobTeaserCard = JobsXingletWrapper<JobTeaserCardProps>(
  '@xing-com/crate-jobs-components-job-teaser-card',
  <JobTeaserCardSkeleton />
);
