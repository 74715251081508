import { useIntl } from 'react-intl';

import { LightMode } from '@xing-com/theme-mode-switcher';
import { Headline, BodyCopy } from '@xing-com/typography';

import { StyledAnchor, StyledCard } from './teaser-card.styles';

type TeaserCard = {
  headline: string;
  description: string;
  trackingParam: string;
  accessibilityDescription: string;
  url: string;
};

export const TeaserCard: React.FC<TeaserCard> = ({
  headline,
  description,
  trackingParam,
  accessibilityDescription,
  url,
}) => {
  const { formatMessage } = useIntl();

  const handleAnchorClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.assign(`${url}${trackingParam}`);
  };

  return (
    <StyledAnchor
      href={url}
      onClick={handleAnchorClick}
      aria-label={`Click to read more about ${accessibilityDescription}`}
    >
      <StyledCard>
        <LightMode>
          <Headline size="xxlarge" noMargin as={'h3'}>
            {formatMessage({ id: headline })}
          </Headline>
          <BodyCopy size="large" noMargin>
            {formatMessage({ id: description })}
          </BodyCopy>
        </LightMode>
      </StyledCard>
    </StyledAnchor>
  );
};
