import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconPlus } from '@xing-com/icons';
import { IllustrationStickerPublicationSettings } from '@xing-com/illustrations';

import { useTrackSaveClick } from '../tracking/tracking';
import { PreferencesForm } from './preferences-form';
import {
  useCreateLoggedOutPreference,
  useReadLoggedOutPreferences,
} from './preferences-hooks';
import {
  Subtitle,
  Title,
  StyledButton,
  StyledContainer,
} from './preferences-section.styles';
import type { Preferences } from './types';

export const PreferencesSection: React.FC = () => {
  const [preferences, setPreferences] = useState<Preferences>({});
  const trackSaveClick = useTrackSaveClick();
  const { loading: loadingPreferences, preferences: fetchedPreferences } =
    useReadLoggedOutPreferences();
  const { create: createPreference } = useCreateLoggedOutPreference();

  useEffect(() => {
    if (!loadingPreferences && fetchedPreferences) {
      setPreferences(fetchedPreferences);
    }
  }, [fetchedPreferences, loadingPreferences]);

  const onSubmit = (): void => {
    const isPreferencesEmpty = !Object.keys(preferences).some((key) =>
      Boolean(preferences[key])
    );

    if (isPreferencesEmpty) {
      trackSaveClick(true);
    } else {
      trackSaveClick(false);

      window.location.assign(
        '/start/signup?signup_channel=minireg_losp_preferences'
      );
    }
  };

  return (
    <StyledContainer>
      <IllustrationStickerPublicationSettings size="large" />
      <Title size="xxlarge" sizeConfined="xxxlarge">
        <FormattedMessage id="LOSP_SEARCH_INSPIRATION_PREFERENCES_MODAL_TITLE" />
      </Title>
      <Subtitle size="medium" sizeConfined="large">
        <FormattedMessage id="LOSP_SEARCH_INSPIRATION_PREFERENCES_MODAL_SUBHEADLINE" />
      </Subtitle>

      <PreferencesForm
        preferences={loadingPreferences ? {} : preferences}
        onChange={(key, value) => {
          if (!loadingPreferences) {
            createPreference(key, value);
            setPreferences((oldPrefs) => ({ ...oldPrefs, [key]: value }));
          }
        }}
      />

      <StyledButton
        size="medium"
        variant="tertiary"
        onClick={onSubmit}
        icon={IconPlus}
        data-qa="losp-preferences-save"
        disabled={
          !preferences.career_level &&
          !preferences.preferred_workplace &&
          !preferences.salary_expectations
        }
      >
        <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_SAVE" />
      </StyledButton>
    </StyledContainer>
  );
};
