import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

import * as S from './ai-search-banner.styles';

type Props = {
  propActionOrigin: string;
  scOParam?: string;
  nwtTracking?: { page?: string; sentBy?: string };
  variant?: 'medium' | 'small';
  className?: string;
};

// Animation magic from Silke
const createAnimationFunction =
  (duration: number, phase: number, min = 0, max = 1) =>
  (timestamp: number) =>
    String(
      (Math.sin(2 * Math.PI * (timestamp / (1000 * duration) - phase)) / 2 +
        0.5) *
        (max - min) +
        min
    );

// light purple
const fx1 = createAnimationFunction(22, 0.1);
const fy1 = createAnimationFunction(16, 0.6);
const fr1 = createAnimationFunction(28, 0.1, 150, 150);

// dark purple
const fx2 = createAnimationFunction(19, 0.5);
const fy2 = createAnimationFunction(23, 0.1);
const fr2 = createAnimationFunction(31, 0.2, 200, 100);

// orange
const fx3 = createAnimationFunction(31, 0);
const fy3 = createAnimationFunction(25, 0.1);
const fr3 = createAnimationFunction(19, 0.3, 300, 250);

export const AiSearchBanner: React.FC<Props> = ({
  propActionOrigin,
  scOParam,
  nwtTracking,
  variant = 'medium',
  className,
}) => {
  const page = nwtTracking?.page || 'jobs/index';
  const animationFrameStart = useRef<number>();
  const requestId = useRef<number>();
  const ref = useRef<HTMLDivElement>(null);
  const trackNwt = useJobsNwtTracking();

  const animate = (timestamp: number): void => {
    const style = ref.current?.style;

    if (!style) {
      return;
    }

    // we need to calculate the time since the first animation started
    const trimmedTimestamp = timestamp - (animationFrameStart.current || 0);

    style.setProperty('--x1', fx1(trimmedTimestamp));
    style.setProperty('--y1', fy1(trimmedTimestamp));
    style.setProperty('--r1', fr1(trimmedTimestamp));

    style.setProperty('--x2', fx2(trimmedTimestamp));
    style.setProperty('--y2', fy2(trimmedTimestamp));
    style.setProperty('--r2', fr2(trimmedTimestamp));

    style.setProperty('--x3', fx3(trimmedTimestamp));
    style.setProperty('--y3', fy3(trimmedTimestamp));
    style.setProperty('--r3', fr3(trimmedTimestamp));

    requestId.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    animationFrameStart.current = performance.now();
    requestId.current = requestAnimationFrame(animate);

    return () => {
      if (requestId.current) {
        cancelAnimationFrame(requestId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = (): void => {
    trackNwt({
      event: 'opened',
      eventSchema: 'basic',
      page,
      element: 'ai_assisted_search',
      ...nwtTracking,
    });
  };

  const url = `${ROUTES.searchAiLanding}?sc_o=${
    scOParam || 'jobs_ai_assisted_search'
  }&sc_o_PropActionOrigin=${propActionOrigin}`;
  const isSmallVariant = variant === 'small';

  return (
    <S.Container to={url} onClick={handleOnClick} className={className}>
      <S.Background ref={ref} $variant={variant}>
        <S.Sparkles>
          <S.Sparkle1 />
          <S.Sparkle2 />
        </S.Sparkles>
        <S.Text size="small" noMargin>
          <FormattedMessage id="JOBS_SEARCH_AI_ENTRYPOINT_BANNER" />
        </S.Text>
        {isSmallVariant ? null : (
          <S.Marker variant="onColor">
            <S.MobileMarkerText>
              <FormattedMessage id="JOBS_SEARCH_AI_ENTRYPOINT_FLAG_MOBILE" />
            </S.MobileMarkerText>
            <S.DesktopMarkerText>
              <FormattedMessage id="JOBS_SEARCH_AI_ENTRYPOINT_FLAG_DESKTOP" />
            </S.DesktopMarkerText>
          </S.Marker>
        )}
        <S.RightArrow height={18} />
      </S.Background>
    </S.Container>
  );
};
