import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Carousel } from '@xing-com/carousel';
import type { CarouselProps } from '@xing-com/carousel';
import { mediaConfinedOnly } from '@xing-com/layout-tokens';
import { spaceS } from '@xing-com/tokens';

type Props = PropsWithChildren & {
  itemWidthMobile?: string;
  itemWidthConfined?: string;
};

const StyledCarousel = styled(Carousel)`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  // space until the edge of the screen
  margin: 0 -20px;
  padding: 0 20px;

  @media ${mediaConfinedOnly} {
    // space until the edge of the screen
    margin: 0 -18px;
    padding: 0 18px;
  }
`;

const getCarouselConfig = (
  itemWidthMobile = '80%',
  itemWidthConfined = '40%'
): Partial<CarouselProps> => ({
  // mobile
  itemWidth: itemWidthMobile,
  itemSpacing: spaceS,

  // tablet
  itemWidthConfined: itemWidthConfined,
  itemSpacingConfined: spaceS,
});

export const JobsRecommendationsCarousel: React.FC<Props> = ({
  children,
  itemWidthMobile,
  itemWidthConfined,
}) => {
  return (
    <StyledCarousel
      tabIndex={-1}
      {...getCarouselConfig(itemWidthMobile, itemWidthConfined)}
    >
      {children}
    </StyledCarousel>
  );
};
