import type React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { CardInspire } from '@xing-com/card';
import type { CardInspireVariants } from '@xing-com/card';
import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { useHost } from '@xing-com/crate-xinglet';
import { mediaTiny } from '@xing-com/layout-tokens';

import {
  StyledIconWrapper,
  StyledHeadline,
  StyledBodyCopy,
} from './inspirational-card.styles';

export type InspirationalCard = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  link: string;
  variant: CardInspireVariants;
  trackingParam?: string;
  jobsCount?: number;
};

export const InspirationalCard: React.FC<InspirationalCard> = ({
  icon: Icon,
  title,
  description,
  link,
  variant,
  trackingParam = '',
  jobsCount = 0,
}) => {
  const isMobile = useMatchMedia(mediaTiny); // be careful: during SSR it will be always true
  const { locale } = useIntl();
  const { isPreview } = useHost();

  const finalLink =
    link === 'https://login.xing.com/'
      ? isPreview
        ? 'https://login.preview.xing.com'
        : 'https://login.xing.com/'
      : link;

  const handleAnchorClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.assign(`${finalLink}${trackingParam}`);
  };

  return (
    <CardInspire
      variant={variant}
      minHeight={isMobile ? '100%' : '150px'}
      size="auto"
      href={link}
      onClick={handleAnchorClick}
    >
      <StyledIconWrapper>
        <Icon height={20} width={20} />
      </StyledIconWrapper>
      <StyledHeadline size="large" forwardedAs="h3">
        <FormattedMessage id={title} />
        {jobsCount > 0 &&
          ` (${new Intl.NumberFormat(locale).format(jobsCount)})`}
      </StyledHeadline>
      <StyledBodyCopy size="medium">
        <FormattedMessage id={description} />
      </StyledBodyCopy>
    </CardInspire>
  );
};
