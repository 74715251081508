/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconAICareerChanger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconAICareerChanger"
    {...props}
  >
    <path
      fill="currentColor"
      d="m14.37 6-2.534-2.252 1.328-1.495L18.505 7l-5.34 4.748-1.33-1.495L14.37 8H9.222c-1.97 0-3.227.468-3.997 1.217C4.461 9.961 4 11.155 4 13.001c0 1.874.42 3.067 1.134 3.802C5.843 17.533 7.029 18 9 18v2c-2.251 0-4.065-.532-5.3-1.802C2.47 16.933 2 15.126 2 13c0-2.155.54-3.961 1.83-5.217C5.116 6.533 6.97 6 9.222 6zM12.398 16.474l.337 1.073c.104.433.44.778.881.882l1.069.333-1.073.336a1.19 1.19 0 0 0-.882.882l-.332 1.069-.337-1.073a1.19 1.19 0 0 0-.882-.882l-1.069-.332 1.073-.337a1.19 1.19 0 0 0 .882-.882zM17.074 11.049l.541 1.723a1.91 1.91 0 0 0 1.416 1.416l1.717.534-1.723.541a1.91 1.91 0 0 0-1.416 1.416l-.535 1.717-.54-1.723a1.91 1.91 0 0 0-1.417-1.416l-1.716-.535 1.723-.54a1.91 1.91 0 0 0 1.416-1.417z"
    />
  </svg>
);
export default IconAICareerChanger;
