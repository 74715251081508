import { useMatch, useNavigate } from '@reach/router';
import { useIntl } from 'react-intl';

import {
  AI_FORCE_CS_ANIMATION_KEY,
  AI_FORCE_CS_ANIMATION_VALUE,
} from '@xing-com/crate-jobs-constants';
import { SearchSuggestion } from '@xing-com/crate-jobs-domain-search-search-suggestions';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { IconAIProfileSearch, IconAICareerChanger } from '@xing-com/icons';

export type SuggestionValues = 'SWIMP' | 'SWIMS';

type BaseProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  kind: keyof typeof AI_FORCE_CS_ANIMATION_VALUE;
  tracking: { element: string; sc_o: string };
};
const BaseSwimpSwims: React.FC<BaseProps> = ({
  Icon,
  title,
  subtitle,
  kind,
  tracking,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const trackNwt = useJobsNwtTracking();

  // Check if regular search or find jobs landing
  const isFindJobs = useMatch(ROUTES.findJobs);

  const handleOnClick = (): void => {
    trackNwt({
      event: 'opened',
      element: tracking.element,
      componentName: 'ai_search',
      eventSchema: 'aicomponent',
      page: isFindJobs ? 'jobs/index' : 'jobs_search/serp',
    });

    const params = new URLSearchParams({
      [AI_FORCE_CS_ANIMATION_KEY]: AI_FORCE_CS_ANIMATION_VALUE[kind],
      sc_o: tracking.sc_o,
      sc_o_PropActionOrigin: isFindJobs ? 'jobs_find_jobs' : 'jobs_serps',
    });
    navigate(`${ROUTES.searchAiLanding}?${params.toString()}`);
  };

  return (
    <SearchSuggestion
      Icon={Icon}
      title={formatMessage({ id: title })}
      subtitle={formatMessage({ id: subtitle })}
      onClick={handleOnClick}
      condensed
    />
  );
};

const SWIMP: React.FC = () => (
  <BaseSwimpSwims
    Icon={IconAIProfileSearch}
    title="JOBS_SEARCH_AI_SWIMP_SUGGESTION"
    subtitle="JOBS_SEARCH_AI_SWIMP_SUGGESTION_SUBTITLE"
    kind="SWIMP"
    tracking={{
      element: 'search_with_my_profile',
      sc_o: 'search_with_my_profile_click',
    }}
  />
);
const SWIMS: React.FC = () => (
  <BaseSwimpSwims
    Icon={IconAICareerChanger}
    title="JOBS_SEARCH_AI_SWIMS_SUGGESTION"
    subtitle="JOBS_SEARCH_AI_SWIMS_SUGGESTION_SUBTITLE"
    kind="SWIMS"
    tracking={{
      element: 'search_change_careers',
      sc_o: 'search_change_careers_click',
    }}
  />
);

type Props = { suggestion: SuggestionValues };
export const Suggestions: React.FC<Props> = ({ suggestion }) => {
  switch (suggestion) {
    case 'SWIMP':
      return <SWIMP />;
    case 'SWIMS':
      return <SWIMS />;
    default:
      return null;
  }
};
