import { useInternalHost } from '@xing-com/crate-xinglet/internal';

export const useServerData = <T>(key: string, initialValue: T): T => {
  const host = useInternalHost();
  if (host.isServer) {
    host.storeServerData?.(key, () => JSON.stringify(initialValue));
    return initialValue;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  } else {
    const json = host.loadServerData?.(key) as string;

    return json ? JSON.parse(json) : initialValue;
  }
};
